<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <!-- Default box -->

          <!-- /.card -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>
